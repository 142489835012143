import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";

import HeaderPopup from "./HeaderPopup";
import HeaderLogo from "./HeaderLogo";
import AiToolsHeaderPopup from "./AiToolsHeaderPopup";
import AiToolsHeaderDropdown from "./AiToolsHeaderDropDown";
import { db } from "../../firebase";
import { useAuth } from "../../Contexts/AuthContext";
import {
  getHomeTabColor,
  getContactUsTabColor,
  getResumeWriterTabColor,
  isWhitelabelFemaleAndFinance,
  getCoverLetterTabColor,
  getServicesTabColor,
  getResumeTabColor,
} from "../../helper/common/commonHelper";
import useFetchWlNameLogoComName from "../../hooks/whiteLabel/useFetchWlNameLogoComName";
import useFetchHeaderTabVisibility from "../../hooks/isShowPlan/useFetchHeaderTabVisibility";
import { logFirebaseEvent } from "../../utils/analytics";
import constant from "../../utils/constant";
import accessConstants from "../../utils/accessConstants";
import { isCustomResumeDomain } from "../../utils/functions";

const { HEADER_TAB_ACCESS } = accessConstants;
const { WL } = constant;

const Header = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation(); // Get current pathname
  const { currentUser, logout } = useAuth();
  const uid = currentUser?.uid;
  const email = currentUser?.email;
  const whiteLabel = localStorage.getItem("whiteLabel") || "";
  const { wlThemeColor } = useFetchWlNameLogoComName();
  const { isShowHeader } = useFetchHeaderTabVisibility();
  const isWlFemaleAndFinance = isWhitelabelFemaleAndFinance();

  const [page, setPage] = useState("");
  const [pageList, setParentPage] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    const urlArray = pathname.split("/");
    setPage(urlArray[urlArray.length - 1]);
    setParentPage(urlArray);
  }, [pathname]);

  const handleLogout = async () => {
    logFirebaseEvent("LOGOUT_BTN_MAIN_HEADER");
    await logout();
    localStorage.clear();
    localStorage.setItem("whiteLabel", whiteLabel);

    if (whiteLabel) {
      navigate(`/login/?wl=${whiteLabel}`);
    } else {
      navigate("/login");
    }
  };

  const checkClientOnboarded = () => {
    db.collection("onboard-clients")
      .doc("onboard")
      .get()
      .then((doc) => {
        if (doc.data()) {
          const { clients = [] } = doc.data();
          if (!isEmpty(clients)) {
            const values = clients.filter(
              (item) => item?.toLowerCase() === email?.toLowerCase()
            );
            const isClientOnboarded = values.length ? true : false;
            if (isClientOnboarded) {
              navigate("/resume-writer");
            } else {
              navigate("/onboard-resume-writer");
            }
          }
        }
      })
      .catch(() => {
        navigate("/");
      });
  };

  const fetchResumeData = () => {
    db.collection("resume")
      .doc(uid)
      .get()
      .then((doc) => {
        if (!isEmpty(doc.data())) {
          const { others = {} } = doc.data();

          if (!isEmpty(others)) {
            navigate("/create-resume/resume", {
              state: { isResumeGenerateThroughAI: false },
            });
          } else {
            navigate("/select-resume-type");
          }
        } else {
          navigate("/select-resume-type");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong. Please try again.", err);
      });
  };

  const handleClickResume = () => {
    logFirebaseEvent("RESUME_TAB_MAIN_HEADER");
    localStorage.setItem("fromWhere", currentUser ? "" : "resume");

    if (currentUser) {
      fetchResumeData();
    } else {
      navigate("/select-template");
    }
  };

  const handleClickResumeWriter = () => {
    logFirebaseEvent("RESUME_WRITING_STUDIO_TAB_MAIN_HEADER");
    localStorage.setItem("fromWhere", currentUser ? "" : "resume-writer");

    if (currentUser) {
      checkClientOnboarded();
    } else {
      navigate("/login");
    }
  };

  const handleContactUs = () => {
    logFirebaseEvent("CONTACT_US_TAB_MAIN_HEADER");
    navigate("/onboard-resume-writer/contact");
  };

  const handleClickLogo = (e) => {
    e.preventDefault();
    logFirebaseEvent("LOGO_MAIN_HEADER");
    navigate(`/?wl=${whiteLabel}`);
  };

  const handleClickHome = (e) => {
    e.preventDefault();
    logFirebaseEvent("HOME_TAB_MAIN_HEADER");
    navigate(`/?wl=${whiteLabel}`);
  };

  const handleClickLogin = () => {
    logFirebaseEvent("LOGIN_BTN_MAIN_HEADER");
    if (whiteLabel) {
      navigate(`/login/?wl=${whiteLabel}`);
    } else {
      navigate("/login");
    }
  };

  const handleClickRegister = () => {
    logFirebaseEvent("SIGNUP_BTN_MAIN_HEADER");

    if (whiteLabel) {
      navigate(`/register/?wl=${whiteLabel}`);
    } else {
      navigate("/register");
    }
  };

  const handleClickCoverLetter = () => {
    logFirebaseEvent("COVER_LETTER_TAB_MAIN_HEADER");

    if (currentUser) {
      navigate("/cover-letter");
    } else {
      navigate("/login");
    }
  };

  const handleClickServices = () => {
    logFirebaseEvent("CARRER_CHOOSING_TAB_MAIN_HEADER");
    navigate("/services");
  };

  const shouldHideLoginButtons =
    search.includes(`?wl=${whiteLabel}`) && pathname.includes("/login");
  const shouldHideSignUpButtons =
    search.includes(`?wl=${whiteLabel}`) && pathname.includes("/register");

  return (
    <>
      <div className="header">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid px-0 px-md-3">
            <a
              className="navbar-brand pointer"
              onClick={(e) => handleClickLogo(e)}
            >
              <div>
                <HeaderLogo />
              </div>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggle"
              aria-controls="navbarToggle"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarToggle">
              <ul className="navbar-nav mx-auto my-3 my-lg-0">
                <li className="nav-item">
                  <a
                    role="button"
                    className="nav-link text-bold mx-1"
                    onClick={(e) => handleClickHome(e)}
                    style={{
                      color: getHomeTabColor(page, wlThemeColor),
                    }}
                  >
                    Home
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    role="button"
                    className="nav-link text-bold mx-1"
                    onClick={handleClickResume}
                    style={{
                      color: getResumeTabColor(
                        page,
                        isWlFemaleAndFinance,
                        pageList
                      ),
                    }}
                  >
                    Resume
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    role="button"
                    className="nav-link text-bold mx-1"
                    onClick={handleClickCoverLetter}
                    style={{
                      color: getCoverLetterTabColor(page, wlThemeColor),
                    }}
                  >
                    Cover Letter
                  </a>
                </li>

                {whiteLabel == "null"
                  ? null
                  : (!currentUser ||
                      HEADER_TAB_ACCESS.includes(email) ||
                      isShowHeader?.isShowResumeTab) && (
                      <li className="nav-item">
                        <a
                          className="nav-link text-bold mx-1"
                          role="button"
                          onClick={handleClickServices}
                          style={{
                            color: getServicesTabColor(
                              page,
                              wlThemeColor,
                              pageList
                            ),
                          }}
                        >
                          Premium Services
                        </a>
                      </li>
                    )}

                {/* {(!currentUser ||
                  isShowHeader?.isShowAiToolsTab ||
                  HEADER_TAB_ACCESS.includes(email)) &&
                  !isCustomResumeDomain() && (
                    <li className="nav-item">
                      <a role="button" className="nav-link text-bold mx-1">
                        <AiToolsHeaderPopup
                          page={page}
                          pageList={pageList}
                          isShowHeader={isShowHeader}
                          wlThemeColor={wlThemeColor}
                          openPopup={openPopup}
                          setOpenPopup={setOpenPopup}
                          setOpenDropdown={setOpenDropdown}
                        />
                      </a>
                    </li>
                  )} */}

                {whiteLabel == "null" ? null : (
                  <li className="nav-item">
                    <a role="button" className="nav-link text-bold mx-1">
                      <AiToolsHeaderDropdown
                        page={page}
                        pageList={pageList}
                        isShowHeader={isShowHeader}
                        wlThemeColor={wlThemeColor}
                        openDropdown={openDropdown}
                        setOpenDropdown={setOpenDropdown}
                        setOpenPopup={setOpenPopup}
                      />
                    </a>
                  </li>
                )}

                {whiteLabel !== WL.FEMALE_AND_FINANCE && (
                  <li className="nav-item">
                    <a
                      role="button"
                      className="nav-link text-bold mx-1"
                      onClick={handleContactUs}
                      style={{
                        color: getContactUsTabColor(page, wlThemeColor),
                      }}
                    >
                      Contact Us
                    </a>
                  </li>
                )}

                {currentUser &&
                  (HEADER_TAB_ACCESS.includes(email) || email === WL.ADMIN) && (
                    <li className="nav-item">
                      <a
                        role="button"
                        className="nav-link text-bold mx-1"
                        onClick={handleClickResumeWriter}
                        style={{
                          color: getResumeWriterTabColor(page, wlThemeColor),
                        }}
                      >
                        Resume Writing Studio
                      </a>
                    </li>
                  )}
              </ul>

              {currentUser ? (
                <HeaderPopup email={email} handleUserLogout={handleLogout} />
              ) : (
                <div className="d-flex mb-3 mb-lg-0">
                  {!shouldHideLoginButtons &&
                    !currentUser &&
                    pathname !== "/login" && (
                      <button
                        className="btn btn-outline-primary"
                        type="button"
                        onClick={handleClickLogin}
                        style={{
                          background: wlThemeColor,
                          borderColor: wlThemeColor,
                          color: wlThemeColor ? "white" : "",
                        }}
                      >
                        Login
                      </button>
                    )}
                  {!shouldHideSignUpButtons && pathname !== "/register" && (
                    <button
                      className="btn btn-primary ms-3 ms-xl-4"
                      type="button"
                      onClick={handleClickRegister}
                      style={{
                        background: wlThemeColor,
                        borderColor: wlThemeColor,
                        color: wlThemeColor ? "white" : "",
                      }}
                    >
                      Sign Up
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
