const TEST_PRICE_ID = {
  // Resume service monthly plan
  STARTER_MONTHLY: "price_1MtmECIE9R1Kn3oUsnGfYWZE",
  PRO_MONTHLY: "price_1MtpTdIE9R1Kn3oUCNRjfNzT",
  ENTERPRISE_MONTHLY: "price_1Mu8nAIE9R1Kn3oU3Ig9yEj3",

  // Resume service yearly plan
  STARTER_YEARLY: "price_1MuD83IE9R1Kn3oUU914IfVD",
  PRO_YEARLY: "price_1MuD98IE9R1Kn3oUyVVlEjAb",
  ENTERPRISE_YEARLY: "price_1MuDABIE9R1Kn3oUKy6QeCg2",
};

const PROD_PRICE_ID = {
  // Resume service monthly plan
  STARTER_MONTHLY: "price_1Mvv1GIE9R1Kn3oUi9VHaN4V",
  PRO_MONTHLY: "price_1Mvv2nIE9R1Kn3oUIGktr9vP",
  ENTERPRISE_MONTHLY: "price_1Mvv3AIE9R1Kn3oUwa6Jc0yf",

  // Resume service yearly plan
  STARTER_YEARLY: "price_1N90qaIE9R1Kn3oUXeK7Y4Dc",
  PRO_YEARLY: "price_1N90vtIE9R1Kn3oUJJevghCz",
  ENTERPRISE_YEARLY: "price_1N913GIE9R1Kn3oUNZJsiu2M",

  // Down pdf-docx plan //
  PDF_DOCX_STARTER_MONTHLY:  "price_1QUkgdIE9R1Kn3oUUaqSA0Yy", // $27 "price_1NLws3IE9R1Kn3oUqbETcmm5", // this one is dynamic based on country // 27 all the time price_1NJTATIE9R1Kn3oUoAxJAg4A",//27 // 37 price_1NH6cKIE9R1Kn3oUtbFzstp1",
  PDF_DOCX_STARTER_YEARLY: "price_1NJlmbIE9R1Kn3oUcRbnrBuf", // price_1NIBSsIE9R1Kn3oUgaTyzQan" //price_1N90i4IE9R1Kn3oUIVrzRdZE",
  ONE_TIME_RESUME_DOWNLOAD: "price_1NVwWrIE9R1Kn3oUkc4qjDt1",

  // Hire
  HIRE_COACH_WRITER: "price_1NlQsWIE9R1Kn3oURwcz5JPG",

  // Premium Services
  BRANDING_BOOST: "price_1OGPjXIE9R1Kn3oUgVu10HE1",
  RESUME_REBRAND: "price_1OBpicIE9R1Kn3oUIhuS1NJV",
};

const STRIPE_SUCCESS_URL = {
  WRITER_CLIENT_BASICINFO: `${window.location.origin}/#/resume-writer/my-clients/basic-info`,
  WRITER_DASHBOARD: `${window.location.origin}/#/resume-writer`,
  SUBSCRIPTION_SCREEN: `${window.location.origin}/#/subscription`,

  DOWN_PDF_DOCX_PLAN: `${window.location.origin}/#/create-resume/resume`,
  DOWN_PDF_DOCX_PLAN_SUCCESS: `${window.location.origin}/#/create-resume/resume/?response=success`,
  DOWN_PDF_DOCX_PLAN_FAILED: `${window.location.origin}/#/create-resume/resume/?response=failed`,
  DOWN_PDF_DOCX_PLAN_COVER_LETTER: `${window.location.origin}/#/cover-letter`,

  RESUME_WRITER_PLAN: `${window.location.origin}/#/resume-writer/my-clients/basic-info`,
  HIRE_COACH_PLAN: `${window.location.origin}/#/ask-for-professional-consultation/contact`,
  RESUME_ATS_FEEDBACK_SCREEN: `${window.location.origin}/#/resume-ATS-feedback`,
  ATS_FEEDBACK_FOR_UPLOADED_RESUME: `${window.location.origin}/#/ATS-feedback-for-uploaded-resume`,
  EXISTING_RESUME_UPLOAD_RESUME: `${window.location.origin}/#/existing-resume-upload`,
  RESUME_UPLOADED_BY_LINKEDIN_SCREEN: `${window.location.origin}/#/resume-upload-by-linkedIn`,
  CREATE_RESUME_EXPERIENCE_SCREEN: `${window.location.origin}/#/create-resume/experience`,
  CREATE_RESUME_SUMMARY_SCREEN: `${window.location.origin}/#/create-resume/summary`,
  PREMIUM_SERVICES: "https://calendly.com/amit_patel",
};

const STRIPE_CANCEL_URL = {
  SUBSCRIPTION_SCREEN: `${window.location.origin}/#/subscription`,
  RESUME_WRITER_PLAN: `${window.location.origin}/#/resume-writer`,
  COVER_LETTER_SCREEN: `${window.location.origin}/#/cover-letter`,
  RESUME_SCREEN: `${window.location.origin}/#/create-resume/resume`,
  PREMIUM_SERVICES: `${window.location.origin}/#/services`,
};

const PLAN_TYPE = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
  ONE_TIME_DOWN: "oneTimeDown",
  FREE_DOWNLOAD: "freeDownload",
  HIRE_COACH_WRITER: "hireCoachWriter",
};

const STRIPE_ROLE = {
  // Resume service monthly plan
  STARTER_MONTHLY: "starterMonthly",
  PRO_MONTHLY: "proMonthly",
  ENTERPRISE_MONTHLY: "enterpriseMonthly",

  // Resume service yearly plan
  STARTER_YEARLY: "starterYearly",
  PRO_YEARLY: "proYearly",
  ENTERPRISE_YEARLY: "enterpriseYearly",

  // Down pdf-docx plan
  PDF_DOCX_STARTER_MONTHLY: "pdf-docx-starter-monthly",
  PDF_DOCX_STARTER_YEARLY: "pdf-docx-starter-yearly",
  ONE_TIME_DOWN: "one-time-resume-download",

  // Hire
  HIRE_COACH_WRITER: "hire-coach-writer",
  BRANDING_BOOST: "branding-boost",
  RESUME_REBRAND: "resume-rebrand",
};

const STRIPE_ROLE_PLAN_OPTIONS = [
  STRIPE_ROLE.STARTER_MONTHLY,
  STRIPE_ROLE.PRO_MONTHLY,
  STRIPE_ROLE.ENTERPRISE_MONTHLY,
  STRIPE_ROLE.STARTER_YEARLY,
  STRIPE_ROLE.PRO_YEARLY,
  STRIPE_ROLE.ENTERPRISE_YEARLY,
  STRIPE_ROLE.BRANDING_BOOST,
  STRIPE_ROLE.RESUME_REBRAND,
];

const STRIPE_ROLE_PLAN_OPTIONS_FOR_DOWNLOAD_PDF_DOCX = [
  STRIPE_ROLE.STARTER_MONTHLY,
  STRIPE_ROLE.PRO_MONTHLY,
  STRIPE_ROLE.ENTERPRISE_MONTHLY,
  STRIPE_ROLE.STARTER_YEARLY,
  STRIPE_ROLE.PRO_YEARLY,
  STRIPE_ROLE.ENTERPRISE_YEARLY,
  STRIPE_ROLE.PDF_DOCX_STARTER_MONTHLY,
  STRIPE_ROLE.PDF_DOCX_STARTER_YEARLY,
  STRIPE_ROLE.HIRE_COACH_WRITER,
  STRIPE_ROLE.BRANDING_BOOST,
  STRIPE_ROLE.RESUME_REBRAND,
];

const LOADING_TIMEOUT = 5000;

export default {
  TEST_PRICE_ID,
  PROD_PRICE_ID,
  PLAN_TYPE,
  STRIPE_ROLE,
  STRIPE_ROLE_PLAN_OPTIONS,
  STRIPE_ROLE_PLAN_OPTIONS_FOR_DOWNLOAD_PDF_DOCX,
  STRIPE_SUCCESS_URL,
  STRIPE_CANCEL_URL,
  LOADING_TIMEOUT,
};
